.dropdown-toggle::after {
  display: none !important;
}

.template-dropdown {
  background-color: white !important;
}

.template-dropdown :hover {
  background-color: transparent;
}